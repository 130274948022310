import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

const Container = styled.div`
  margin: 20px;
  padding: 20px;
  border-radius: 10px;
  background-color: #161616;
`;

const Title = styled.h2`
  margin-bottom: 20px;
  text-align: center;
  color: #fff;
`;

const List = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`;

const ListItem = styled.li`
  display: flex;
  justify-content: space-between;
  padding: 10px 15px;
  border-bottom: 1px solid #161616;

  &:last-child {
    border-bottom: none;
  }

  &:nth-child(odd) {
    background-color: #212121;
  }
`;

const Rank = styled.span`
  font-weight: bold;
  color: #007bff;
`;

const Country = styled.span`
  flex-grow: 1;
  text-align: left;
  padding-left: 10px;
  color: #c3c3c3;
`;

const Value = styled.span`
  font-weight: bold;
  color: #28a745;
`;

const generateRandomVisits = () => {
  const numbers = [70, 87, 64, 59, 120, 200];
  return numbers[Math.floor(Math.random() * numbers.length)];
};

const getTodayKey = () => {
  const today = new Date();
  return today.toISOString().split("T")[0]; // Formato: YYYY-MM-DD
};

const CountryList = () => {
  const [dailyVisits, setDailyVisits] = useState(null);

  useEffect(() => {
    const todayKey = getTodayKey();
    const storedData = JSON.parse(localStorage.getItem("dailyVisits")) || {};

    if (storedData.date === todayKey) {
      // Use o número já armazenado para o dia
      setDailyVisits(storedData.visits);
    } else {
      // Gere um novo número e armazene
      const newVisits = generateRandomVisits();
      localStorage.setItem(
        "dailyVisits",
        JSON.stringify({ date: todayKey, visits: newVisits })
      );
      setDailyVisits(newVisits);
    }
  }, []);

  const data = [
    { rank: 1, country: "Brasil", value: 231 },
    { rank: 2, country: "Estados Unidos", value: 171 },
    { rank: 3, country: "Alemanha", value: 61 },
    { rank: 4, country: "Irlanda", value: 36 },
    { rank: 5, country: "República da Coreia", value: 28 },
    { rank: 6, country: "Reino Unido", value: 14 },
    { rank: 7, country: "Bélgica", value: 12 },
    { rank: 8, country: "Malásia", value: 11 },
    { rank: 9, country: "Suécia", value: 6 },
    { rank: 10, country: "Canadá", value: 5 },
  ];

  return (
    <Container>
      <Title>Visitas únicas: <Value>{dailyVisits ?? "Carregando..."}</Value> </Title>
      <Title>Últimos 7 dias: <Value>596 Visitas</Value></Title>
      <List>
        {data.map((item) => (
          <ListItem key={item.rank}>
            <Rank>{item.rank}</Rank>
            <Country>{item.country}</Country>
            <Value>{item.value}</Value>
          </ListItem>
        ))}
      </List>
    </Container>
  );
};

export default CountryList;
