import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { supabase } from './supabaseClient.js';
import Views from './Views.js';

const ListWrapper = styled.div`
  padding: 20px;
  max-width: 800px;
  margin: auto;
  background-color: #212121;
  border-radius: 8px;
  color: white;
`;

const Title = styled.h2`
  font-size: 24px;
  color: white;
  margin-bottom: 20px;
  text-align: center;
`;

const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
`;

const Input = styled.input`
  padding: 10px;
  border: 1px solid #444;
  border-radius: 5px;
  background-color: #161616;
  color: white;
  margin-bottom: 10px;
  width: 100%;
  max-width: 300px;
`;

const Button = styled.button`
  padding: 10px 20px;
  background-color: #aa7d3c;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    background-color: #f2cf8d;
  }
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
`;

const TableHead = styled.thead`
  background: linear-gradient(to right, #aa7d3c, #f2cf8d);
  color: white;
`;

const TableHeader = styled.th`
  padding: 10px;
  text-align: left;
  border: 1px solid #444;
`;

const TableBody = styled.tbody`
  tr:nth-child(even) {
    background-color: #2a2a2a;
  }
`;

const TableRow = styled.tr`
  &:hover {
    background-color: #161616;
  }
`;

const TableCell = styled.td`
  padding: 10px;
  border: 1px solid #444;
  text-align: left;
`;

const ErrorMessage = styled.p`
  color: red;
  text-align: center;
`;

const ViewCount = styled.p`
  font-size: 18px;
  color: #f2cf8d;
  text-align: center;
`;

const NewsletterList = () => {
  const [subscribers, setSubscribers] = useState([]);
  const [error, setError] = useState('');
  const [password, setPassword] = useState('');
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [viewCount, setViewCount] = useState(0);

  const correctPassword = 'Sirius1010@'; // Substitua pela senha desejada

  const handleLogin = () => {
    if (password === correctPassword) {
      setIsAuthenticated(true);
    } else {
      alert('Senha incorreta. Tente novamente.');
    }
  };

  useEffect(() => {
    // Função para registrar uma view
    const registerView = async () => {
      const today = new Date().toISOString().split('T')[0]; // Formato YYYY-MM-DD
      const visitorKey = `visitor_${today}`; // Chave única para o dia

      // Verifica se o visitante já entrou hoje
      if (!localStorage.getItem(visitorKey)) {
        // Marca que o visitante entrou hoje
        localStorage.setItem(visitorKey, 'true');
        
        // Registra a view no Supabase
        await supabase
          .from('views') // Nome da tabela de views
          .upsert([{ date: today, count: 1 }], { onConflict: ['date'] });
      }
    };

    // Função para buscar o contador de views do dia atual
    const fetchViewCount = async () => {
      const today = new Date().toISOString().split('T')[0]; // Formato YYYY-MM-DD
      const { data, error } = await supabase
        .from('views')
        .select('count')
        .eq('date', today)
        .single();

      if (error) {
        console.error(error.message);
      } else {
        setViewCount(data ? data.count : 0);
      }
    };

    if (isAuthenticated) {
      // Registrar view e buscar contagem de views
      registerView();
      fetchViewCount();
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (isAuthenticated) {
      const fetchSubscribers = async () => {
        setError('');
        try {
          const { data, error } = await supabase
            .from('news') // Nome da tabela no Supabase
            .select('*');

          if (error) {
            throw error;
          }

          setSubscribers(data || []);
        } catch (err) {
          setError('Erro ao carregar os dados. Tente novamente.');
          console.error(err.message);
        }
      };

      fetchSubscribers();
    }
  }, [isAuthenticated]);

  return (
    <ListWrapper>
      {!isAuthenticated ? (
        <InputWrapper>
          <Title>Digite a senha para acessar a lista</Title>
          <Input
            type="password"
            placeholder="Senha"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <Button onClick={handleLogin}>Entrar</Button>
        </InputWrapper>
      ) : (
        <>
        <Views/>
          <Title>Lista de Inscritos</Title>
          
          {error && <ErrorMessage>{error}</ErrorMessage>}
          {subscribers.length > 0 ? (
            <Table>
              <TableHead>
                <tr>
                  <TableHeader>ID</TableHeader>
                  <TableHeader>Email</TableHeader>
                  <TableHeader>Telefone</TableHeader>
                </tr>
              </TableHead>
              <TableBody>
                {subscribers.map((subscriber) => (
                  <TableRow key={subscriber.id}>
                    <TableCell>{subscriber.id}</TableCell>
                    <TableCell>{subscriber.email}</TableCell>
                    <TableCell>{subscriber.phone}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          ) : (
            <p style={{ textAlign: 'center' }}>Nenhum inscrito encontrado.</p>
          )}
        </>
      )}
    </ListWrapper>
  );
};

export default NewsletterList;
