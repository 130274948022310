import React from "react";
import styled from 'styled-components';
import Foto from '../../assets/logo.png';

const Container = styled.div`
  background: #111111;
  padding: 2rem;
`;

const Title = styled.h1`
  text-align: left;
  color: white;
  font-size: 1.5rem;
  font-weight: 600;
  margin: 1rem;
`;

const TitleSub = styled.h1`
  text-align: left;
  color: #c3c3c3;
  font-size: 1.3rem;
  font-weight: 400;
  margin-bottom: 2rem;
`;


const Imagem = styled.img`
    max-width: 300px;
    width: 100%;
`;


function Politica() {
    
    
    return (
        <Container>
            <Imagem src={Foto}/>
            <Title>Política de Privacidade</Title>
            <TitleSub>O aplicativo Sirius Robot é um portal de notícias financeiras e de resultados. Não coletamos, armazenamos ou compartilhamos informações pessoais dos usuários. O objetivo do aplicativo é exclusivamente fornecer conteúdos informativos e atualizações financeiras de maneira transparente e segura.</TitleSub>
        
            <Title>1. Coleta de Dados</Title>
            <TitleSub>Não realizamos nenhuma coleta de dados pessoais ou confidenciais dos usuários.</TitleSub>


            <Title>2. Uso do Aplicativo</Title>
            <TitleSub>O Sirius Robot foi projetado para oferecer notícias e resultados financeiros diretamente aos usuários. Todo o conteúdo é acessado sem a necessidade de cadastro, login ou fornecimento de qualquer tipo de dado pessoal.</TitleSub>


            <Title>3. Segurança e Transparência</Title>
            <TitleSub>Por não coletarmos dados, garantimos que nenhuma informação sensível do usuário será armazenada, compartilhada ou utilizada para outros fins.</TitleSub>


            <Title>4. Alterações na Política de Privacidade</Title>
            <TitleSub>Caso haja alterações futuras que incluam coleta de informações, esta Política de Privacidade será atualizada e os usuários serão devidamente informados.</TitleSub>

            <Title>5. Contato</Title>
            <TitleSub>Se houver dúvidas sobre esta Política de Privacidade, entre em contato conosco pelo e-mail email.risemode@gmail.com Última atualização: 18 de novembro de 2024.</TitleSub>
        
        </Container>
    );
}

export default Politica;
