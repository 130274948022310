// src/components/Newsletter.js
import React, { useState } from 'react';
import styled from 'styled-components';
import { supabase } from './supabaseClient.js';
import { useTranslation } from 'react-i18next';

const Container = styled.div`

    margin: 1rem;
`;

const NewsletterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px;
  border-radius: 8px;
  max-width: 400px;
  margin: auto;
  background-color: #212121;
`;

const Title = styled.h2`
  font-size: 24px;
  color: #fff;
  margin-bottom: 16px;
  text-align: center;
`;

const Input = styled.input`
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
`;

const Button = styled.button`
  width: 100%;
  padding: 10px;
  background: linear-gradient(to right, #aa7d3c, #f2cf8d);
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;

  &:hover{
    filter: brightness(0.8);
  }
`;

const SuccessMessage = styled.p`
  color: green;
  font-size: 16px;
`;

const ErrorMessage = styled.p`
  color: red;
  font-size: 16px;
`;

const Newsletter = () => {
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [success, setSuccess] = useState('');
  const [error, setError] = useState('');
  const { t } = useTranslation();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSuccess('');
    setError('');

    if (!email || !phone) {
      setError('Por favor, preencha todos os campos.');
      return;
    }

    try {
      const { data, error } = await supabase
        .from('news') // Nome da tabela no Supabase
        .insert([{ email, phone }]);

      if (error) {
        throw error;
      }

      setSuccess('Enviado com sucesso!');
      setEmail('');
      setPhone('');
    } catch (err) {
      setError('Erro ao enviar os dados. Tente novamente.');
      console.error(err.message);
    }
  };

  return (
    <Container>
    <NewsletterWrapper>
      <Title> {t('contato.title')}</Title>
      {success && <SuccessMessage>{success}</SuccessMessage>}
      {error && <ErrorMessage>{error}</ErrorMessage>}
      <form onSubmit={handleSubmit}>
        <Input
          type="email"
          placeholder="sirius@siriusrobot.com"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <Input
          type="tel"
          placeholder="+1(99)99999-99999"
          value={phone}
          onChange={(e) => setPhone(e.target.value)}
        />
        <Button type="submit">{t('enviar.title')}</Button>
      </form>
    </NewsletterWrapper>
    </Container>
  );
};

export default Newsletter;
