import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { supabase } from '../Resultados/supabaseClient.js'; // Certifique-se de usar o caminho correto
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom'; // Importe useNavigate

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #111111;
  color: white;
  min-height: 100vh;
  padding: 20px;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 400px;
  gap: 15px;
`;

const Label = styled.label`
  font-size: 18px;
  font-weight: bold;
`;

const Input = styled.input`
  padding: 10px;
  font-size: 16px;
  border: 1px solid #444;
  border-radius: 5px;
  background: #222;
  color: white;
`;

const Button = styled.button`
  padding: 10px;
  font-size: 18px;
  border: none;
  border-radius: 5px;
  background: greenyellow;
  color: #111;
  cursor: pointer;
  font-weight: bold;

  &:hover {
    background: limegreen;
  }
`;

const EditPage = () => {
  const [authenticated, setAuthenticated] = useState(false);
  const [password, setPassword] = useState('');
  const [data, setData] = useState(null);
  const { register, handleSubmit, reset } = useForm();
  const navigate = useNavigate(); // Hook para navegação
  const CORRECT_PASSWORD = 'sirius1010@'; // Substitua por sua senha desejada

  useEffect(() => {
    if (authenticated) {
      // Fetch initial data apenas se autenticado
      const fetchData = async () => {
        const { data: result, error } = await supabase
          .from('sirius') // Substitua pelo nome da sua tabela
          .select('*')
          .eq('id', 1); // Buscar pelo ID 1

        if (error) {
          console.error('Erro ao buscar dados:', error);
        } else {
          setData(result[0]); // Como só tem um resultado, pegue o primeiro item
          reset(result[0]); // Preenche o formulário com os dados atuais
        }
      };

      fetchData();
    }
  }, [authenticated, reset]);

  const handleLogin = (e) => {
    e.preventDefault();
    if (password === CORRECT_PASSWORD) {
      setAuthenticated(true);
    } else {
      alert('Senha incorreta!');
    }
  };

  const onSubmit = async (formData) => {
    const { error } = await supabase
      .from('sirius')
      .update(formData)
      .eq('id', 1); // Atualizar pelo ID 1

    if (error) {
      console.error('Erro ao atualizar dados:', error);
    } else {
      alert('Dados atualizados com sucesso!');
      setData(formData); // Atualiza os dados locais

      // Redireciona para a página principal
      navigate('/'); // Caminho para a página principal
    }
  };

  if (!authenticated) {
    // Tela de Login
    return (
      <Container>
        <h1>Autenticação</h1>
        <Form onSubmit={handleLogin}>
          <Label htmlFor="password">Senha:</Label>
          <Input
            id="password"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <Button type="submit">Entrar</Button>
        </Form>
      </Container>
    );
  }

  if (!data) {
    // Carregando os dados
    return (
      <Container>
        <p>Carregando...</p>
      </Container>
    );
  }

  return (
    <Container>
      <h1>Editar Dados</h1>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Label htmlFor="ganho">Ganho:</Label>
        <Input id="ganho" {...register('ganho')} />

        <Label htmlFor="abs">ABS:</Label>
        <Input id="abs" {...register('abs')} />

        <Label htmlFor="diariamente">Diariamente:</Label>
        <Input id="diariamente" {...register('diariamente')} />

        <Label htmlFor="mensalmente">Mensalmente:</Label>
        <Input id="mensalmente" {...register('mensalmente')} />

        <Label htmlFor="rebaixamento">Rebaixamento:</Label>
        <Input id="rebaixamento" {...register('rebaixamento')} />

        <Button type="submit">Salvar</Button>
      </Form>
    </Container>
  );
};

export default EditPage;
